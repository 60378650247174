<template>
  <div class="cart">
    <h1 class="page-title">Your Cart</h1>
    <div class="row">
      <div class="col-12 col-md-8">
        <div class="product-listing">
          <product-cart-item :product="product" v-for="product in this.$store.getters.cart" :key="product.id" />
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="cart-sidebar">
          <div class="sidebar-widget">
            <b-btn variant="primary" block to="/cart/review" v-if="loggedIn && this.$store.getters.cart.length > 0">
              Review and pay
            </b-btn>
            <b-btn variant="primary" block to="/login" v-if="!loggedIn && this.$store.getters.cart.length > 0">
              Log In AND CHECK OUT
            </b-btn>
            <b-btn variant="primary" block to="/" v-if="this.$store.getters.cart.length == 0">
              Buy
            </b-btn>
            <div class="sidebar-total">
              <p>
                Order Total
                <span>
                  {{ total | currency }}
                </span>
              </p>
            </div>
          </div>
          <div class="sidebar-widget product-info" v-for="product in this.$store.getters.cart" :key="product.id">
            <ul class="list-unstyled">
              <li>
                <p>{{product.quantity}}x {{product.name}}</p>
                <span>{{product.price * product.quantity | currency}}</span>
              </li>
              <li class="info-small" v-for="ticket in product.tickets" :key="ticket.id">
                <p>{{ ticket.ticket_name }}</p>
                <span v-if="false">COVID test € 10,00</span>
              </li>
              <li class="info-small" v-if="product.vat > 0">
                <p>Tax {{ product.vat | currency }}%</p>
                <span>{{ product.quantity }}</span>
              </li>
            </ul>
          </div>

          <b-btn class="mt-4" size="sm" variant="outline-danger" @click.stop="clearCart">Clear cart</b-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductCartItem from '@/components/ProductCartItem.vue'
import store from '../store';
export default {
  name: 'Cart',
  metaInfo: {
    title: 'Your Cart',
  },
  components: {
    ProductCartItem
  },
  computed: {
    total() {
      return this.$store.getters.cart.reduce((prev, cur) => prev + cur.total, 0)
    }
  },
  data() {
    return {
      quantity: 0,
      loggedIn: false
    }
  },
  mounted() {
    this.initialize()
  },
  methods: {
    initialize() {
      this.loggedIn = store.getters['AuthModule/loggedIn'];
    },

    clearCart() {
      if(confirm('Are you sure that you want to remove all items on your cart and start over?')) {
        this.$store.commit("updateCart", []);
        setTimeout(() => {
          this.$router.push(`/`);
        }, 1000);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.page-title {
  margin: 6rem 0 3rem;
  font-size: 47px;
  font-weight: 700;

  @media screen and (max-width: 770px) {
    margin-top: 15px;
    line-height: 1.0;
  }
}

</style>
